.container {
    min-height: 100vh;
    font-family: 'Open Sans';
}

/* Для планшетов и больших смартфонов */
@media (max-width: 858px) and (orientation: portrait) {
    .container {
        overflow: scroll;
    }
}
