@font-face {
    font-family: 'RwWidgets';
    font-weight: normal;
    font-style: normal;
    src: url('../fonts/rw-widgets.eot?v=4.1.0');
    src: url('../fonts/rw-widgets.eot?#iefix&v=4.1.0') format('embedded-opentype'), url('../fonts/rw-widgets.woff?v=4.1.0') format('woff'), url('../fonts/rw-widgets.ttf?v=4.1.0') format('truetype'), url('../fonts/rw-widgets.svg?v=4.1.0#fontawesomeregular') format('svg')
}

:global .rw-btn,
:global .rw-input-reset,
:global .rw-input,
:global .rw-dropdown-list-autofill,
:global .rw-filter-input {
    color: inherit;
    padding: 0;
    margin: 0;
    border: none;
    box-shadow: none;
    background: none;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
}

:global .rw-btn::-moz-focus-inner {
    padding: 0;
    border: 0;
}

:global select.rw-input {
    text-transform: none;
}

:global html input[type="button"].rw-input {
    -webkit-appearance: button;
    cursor: pointer;
}

:global textarea.rw-input {
    overflow: auto;
    resize: vertical;
}

:global button[disabled].rw-input,
:global fieldset[disabled] .rw-input,
:global html input[disabled].rw-input {
    cursor: not-allowed;
}

:global button.rw-input::-moz-focus-inner,
:global input.rw-input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

:global input[type="checkbox"],
:global input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
}

:global .rw-i {
    display: inline-block;
    color: inherit;
    font-family: RwWidgets;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

:global .rw-i-caret-down:before {
    font-family: Glyphter;
    content: 'a';
    font-size: 10px;
}

:global .rw-i-caret-up:before {
    content: '\e800';
}

:global .rw-i-chevron-left:before {
    content: '\f104';
}

:global .rw-i-chevron-right:before {
    content: '\f105';
}

:global .rw-i-clock-o:before {
    content: '\e805';
}

:global .rw-i-calendar:before {
    content: '\e804';
}

:global .rw-i-search:before {
    content: '\e801';
}

:global .rw-btn {
    position: relative;
    color: #333;
    display: inline-block;
    text-align: center;
    /*vertical-align:middle;*/
    border: 1px solid transparent;
    cursor: pointer;
    outline: none;
    padding-right: 2px;
}

:global .rw-state-readonly .rw-btn,
:global .rw-state-disabled .rw-btn {
    cursor: not-allowed;
}

:global .rw-btn-select {
    opacity: .75;
    transition: opacity 150ms ease-in;
}

:global .rw-btn-select:hover,
:global .rw-state-focus .rw-btn-select,
:global :hover>.rw-btn-select {
    opacity: 1;
}

:global .rw-btn-primary {
    width: 100%;
    white-space: normal;
    line-height: 2em;
}

:global .rw-btn-primary:hover {
    background-color: #e6e6e6;
}

:global .rw-btn-select[disabled],
:global .rw-btn-primary[disabled],
:global fieldset[disabled] .rw-btn-select,
:global fieldset[disabled] .rw-btn-primary {
    box-shadow: none;
    cursor: not-allowed;
    opacity: .65;
    pointer-events: none;
}

:global .rw-sr {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

:global .rw-widget {
    background-clip: border-box;
    border: none;
    color: #333;
    font-size: 1em;
    font-family: inherit;
    outline: none;
    position: relative;
}

:global .rw-widget,
:global .rw-widget * {
    box-sizing: border-box;
}

:global .rw-widget:before,
:global .rw-widget *:before,
:global .rw-widget:after,
:global .rw-widget *:after {
    box-sizing: border-box;
}

:global .rw-widget>.rw-widget-container {
    width: 100%;
    margin: 0;
}

:global .rw-widget-container {
    background-color: #fff;
    /*height: 32px;*/
    border-radius: 2px;
    /*border: solid 1px #777a82;*/
}

:global .rw-widget-container.rw-state-focus,
:global .rw-state-focus>.rw-widget-container,
:global .rw-widget-container.rw-state-focus:hover,
:global .rw-state-focus>.rw-widget-container:hover {
    background-color: #fff;
    border: solid 2px #000;
    /*border-color:#66afe9;*/
    /*box-shadow:0 0 8px rgba(102,175,233,0.6);*/
}

:global .rw-widget-container.rw-state-readonly,
:global .rw-state-readonly>.rw-widget-container {
    cursor: not-allowed;
}

:global .rw-widget-container.rw-state-disabled,
:global .rw-state-disabled>.rw-widget-container,
:global fieldset[disabled] .rw-widget-container,
:global .rw-widget-container.rw-state-disabled:hover,
:global .rw-state-disabled>.rw-widget-container:hover,
:global fieldset[disabled] .rw-widget-container:hover,
:global .rw-widget-container.rw-state-disabled:active,
:global .rw-state-disabled>.rw-widget-container:active,
:global fieldset[disabled] .rw-widget-container:active {
    box-shadow: none;
    cursor: not-allowed;
}

:global .rw-widget-picker {
    position: relative;
    overflow: hidden;
    border-collapse: separate;
    display: inline-table;
    height: 33px;
    border: solid 1px #777a82;
}

:global .rw-widget-picker>* {
    position: relative;
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
    display: table-cell;
}

:global .rw-widget-picker>.rw-select {
    width: 1%;
    white-space: nowrap;
}

:global .rw-open>.rw-widget-picker {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

:global .rw-open-up>.rw-widget-picker {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

:global fieldset[disabled] .rw-widget-picker,
:global .rw-state-disabled>.rw-widget-picker {
    background-color: #eee;
}

:global .rw-multiselect>.rw-widget-picker {
    height: auto;
    min-height: 36px;
}

:global .rw-select {
    cursor: pointer;
}

:global .rw-select>* {
    width: 1.9em;
    height: 100%;
}

:global .rw-state-readonly .rw-select,
:global .rw-state-disabled .rw-select {
    cursor: not-allowed;
}

:global .rw-select-bordered {
    cursor: pointer;
    border: none;
    border-left: #777a82 1px solid;
}

:global .rw-select-bordered:hover,
:global .rw-select-bordered:active {
    background-color: #e6e6e6;
}

:global .rw-select-bordered:active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

:global .rw-state-disabled .rw-select-bordered,
:global .rw-state-readonly .rw-select-bordered,
:global fieldset[disabled] .rw-select-bordered,
:global .rw-state-disabled .rw-select-bordered:hover,
:global .rw-state-readonly .rw-select-bordered:hover,
:global fieldset[disabled] .rw-select-bordered:hover,
:global .rw-state-disabled .rw-select-bordered:active,
:global .rw-state-readonly .rw-select-bordered:active,
:global fieldset[disabled] .rw-select-bordered:active {
    cursor: not-allowed;
    background-color: inherit;
    background-image: none;
    box-shadow: none;
}

:global .rw-rtl .rw-select-bordered {
    border-right: #ccc 1px solid;
    border-left: none;
}

:global .rw-rtl {
    direction: rtl;
}

:global .rw-input-reset,
:global .rw-input,
:global .rw-dropdown-list-autofill,
:global .rw-filter-input {
    outline: 0;
}

:global .rw-input-reset::-moz-placeholder {
    color: #999;
    opacity: 1;
}

:global .rw-input-reset:-ms-input-placeholder {
    color: #999;
}

:global .rw-input-reset::-webkit-input-placeholder {
    color: #999;
}

:global .rw-input,
:global .rw-dropdown-list-autofill,
:global .rw-filter-input {
    color: #555;
    padding: 0 10px;
    background-color: #fff;
}

:global .rw-input[type="text"]::-ms-clear {
    display: none;
}

:global .rw-input[disabled],
:global fieldset[disabled] .rw-input {
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
    background-color: #eee;
    border-color: #ccc;
}

:global .rw-input[readonly] {
    cursor: not-allowed;
}

:global .rw-i.rw-loading {
    display: block;
    background: url('../img/loading.gif') no-repeat center;
    min-width: 16px;
    width: 1.9em;
    height: 16px;
}

:global .rw-i.rw-loading:before {
    content: "";
}

:global .rw-placeholder {
    color: #999;
    font-weight: 400;
}

:global .rw-detect-autofill:-webkit-autofill {
    animation-name: react-widgets-autofill-start;
    transition: background-color 50000s ease-in-out 0s;
}

:global .rw-detect-autofill:not(:-webkit-autofill) {
    animation-name: react-widgets-autofill-cancel;
}

:global .rw-webkit-autofill .rw-widget-container,
:global .rw-input:-webkit-autofill {
    background-color: #faffbd !important;
    background-image: none !important;
    color: #000 !important;
}

:global .rw-widget-input,
:global .rw-filter-input {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

:global .rw-widget-input.rw-state-focus {
    box-shadow: 0 0 8px rgba(102, 175, 233, 0.6), inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

:global .rw-widget-input>div:not([class]) {
    /*display: flex;*/
    padding-left: 6px;
}

:global .rw-list {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 1em;
    outline: 0;
    overflow: auto;
    max-height: 200px;
}

:global .rw-list-option {
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    border: 1px solid transparent;
    font-size: 14px;
    font-weight: 600;
    color: #000;
}

:global .rw-list-option.rw-state-focus,
:global .rw-list-option.rw-state-focus:hover {
    background-color: transparent;
    /*border-color: #66afe9;*/
    color: #333;
}

:global .rw-list-option:hover,
:global .rw-list-option:hover.rw-state-focus {
    background-color: #e6e6e6;
    border-color: #e6e6e6;
    color: #333;
}

:global .rw-list-option.rw-state-selected,
:global .rw-list-option.rw-state-selected:hover {
    /*background-color: #333;*/
    /*border-color: #333;*/
    /*color: white;*/
}

:global .rw-list-option.rw-state-selected *,
:global .rw-list-option.rw-state-selected:hover * {
    /*color: white;*/
}

:global fieldset[disabled] .rw-list-option,
:global .rw-list-option.rw-state-disabled,
:global .rw-list-option.rw-state-readonly {
    box-shadow: none;
    cursor: not-allowed;
    color: #999;
    opacity: .7
}

:global fieldset[disabled] .rw-list-option:hover,
:global .rw-list-option.rw-state-disabled:hover,
:global .rw-list-option.rw-state-readonly:hover {
    background: none;
    border-color: transparent;
}

:global .rw-list-empty,
:global .rw-list-option,
:global .rw-list-optgroup {
    padding: 8px.75em;
    outline: 0;
}

:global .rw-list-empty {
    font-size: 13px;
}

:global .rw-list-optgroup {
    font-weight: bold;
    padding-top: 7px;
}

:global .rw-list-option-create {
    border-top: 1px #ccc solid;
}

:global .rw-dropdown-list-autofill {
    padding: 0;
}

:global .rw-dropdown-list-input {
    background-color: transparent;
    vertical-align: middle;
    padding-right: 0;
    max-width: 1px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

:global .rw-rtl .rw-dropdown-list-input {
    padding-right: .857em;
    padding-left: 0;
}

:global .rw-filter-input {
    position: relative;
    margin: 4px;
    padding-right: 0;
}

:global .rw-filter-input .rw-rtl {
    padding-right: .857em;
    padding-left: 0;
}

:global .rw-filter-input .rw-select,
:global .rw-filter-input .rw-btn {
    opacity: .75;
    cursor: text;
}

:global .rw-filter-input>.rw-select,
:global .rw-filter-input>.rw-select:active,
:global .rw-filter-input>.rw-select:hover {
    background: none;
    cursor: initial;
    box-shadow: none;
}

:global .rw-number-picker .rw-btn {
    cursor: pointer;
    height: calc(1.2145em - 1px);
    margin-top: -1px\9;
    height: 1.2145em\9;
    /*line-height:1.2145em;*/
    line-height: calc(1.2145em - 1px);
    display: block;
    border: none;
}

:global .rw-number-picker .rw-btn:hover,
:global .rw-number-picker .rw-btn:active {
    background-color: #e6e6e6;
}

:global .rw-number-picker .rw-btn:active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

:global .rw-state-disabled .rw-number-picker .rw-btn,
:global .rw-state-readonly .rw-number-picker .rw-btn,
:global fieldset[disabled] .rw-number-picker .rw-btn,
:global .rw-state-disabled .rw-number-picker .rw-btn:hover,
:global .rw-state-readonly .rw-number-picker .rw-btn:hover,
:global fieldset[disabled] .rw-number-picker .rw-btn:hover,
:global .rw-state-disabled .rw-number-picker .rw-btn:active,
:global .rw-state-readonly .rw-number-picker .rw-btn:active,
:global fieldset[disabled] .rw-number-picker .rw-btn:active {
    cursor: not-allowed;
    background-color: inherit;
    background-image: none;
    box-shadow: none;
}

:global .rw-number-picker .rw-select {
    vertical-align: middle;
}

:global .rw-number-picker .rw-select,
:global .rw-number-picker .rw-select:hover,
:global .rw-number-picker .rw-select:active {
    box-shadow: none;
}

:global .rw-calendar-popup {
    right: auto;
    min-width: 0;
    width: 18em;
}

:global .rw-calendar {
    border-radius: 4px;
    background-color: #fff;
    border: #ccc 1px solid;
    overflow: hidden;
}

:global .rw-calendar.rw-popup {
    border-color: #ccc;
}

:global .rw-calendar-now {
    font-weight: bold;
}

:global .rw-calendar-btn-left,
:global .rw-calendar-btn-right {
    width: 12.5%;
}

:global .rw-calendar-btn-view {
    width: 75%;
}

:global .rw-calendar-footer {
    border-top: 1px solid #ccc;
}

:global .rw-calendar-grid {
    outline: none;
    height: 14.28571429em;
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    background-color: #fff;
}

:global .rw-head-cell {
    text-align: center;
    border-bottom: 1px solid #ccc;
    padding: .25em;
}

:global .rw-cell {
    color: #333;
    border-radius: 4px;
    cursor: pointer;
    line-height: normal;
    text-align: center;
    border: 1px solid transparent;
    padding: .25em;
}

:global .rw-cell:hover {
    background-color: #e6e6e6;
    border-color: #e6e6e6;
    color: #333;
}

:global .rw-cell.rw-state-focus,
:global .rw-cell.rw-state-focus:hover {
    background-color: transparent;
    border-color: #66afe9;
    color: #333;
}

:global .rw-cell.rw-state-selected,
:global .rw-cell.rw-state-selected:hover {
    background-color: #337ab7;
    border-color: #337ab7;
    color: white;
}

:global .rw-cell.rw-state-disabled {
    color: #999;
    opacity: .7;
}

:global .rw-cell.rw-state-disabled:hover {
    background: none;
    border-color: transparent;
}

:global .rw-calendar-month .rw-cell {
    text-align: center;
}

:global .rw-cell-off-range {
    color: #999;
}

:global .rw-calendar-transition-group {
    position: relative;
}

:global .rw-calendar-transition {
    transition: transform 300ms;
    overflow: hidden;
}

:global .rw-calendar-transition-top {
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
}

:global .rw-calendar-transition-bottom {
    -ms-transform: translateY(100%);
    transform: translateY(100%);
}

:global .rw-calendar-transition-right {
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
}

:global .rw-calendar-transition-left {
    -ms-transform: translateX(100%);
    transform: translateX(100%);
}

:global .rw-calendar-transition-entering.rw-calendar-transition-top,
:global .rw-calendar-transition-entered.rw-calendar-transition-top,
:global .rw-calendar-transition-entering.rw-calendar-transition-bottom,
:global .rw-calendar-transition-entered.rw-calendar-transition-bottom {
    -ms-transform: translateY(0);
    transform: translateY(0);
}

:global .rw-calendar-transition-entering.rw-calendar-transition-right,
:global .rw-calendar-transition-entered.rw-calendar-transition-right,
:global .rw-calendar-transition-entering.rw-calendar-transition-left,
:global .rw-calendar-transition-entered.rw-calendar-transition-left {
    -ms-transform: translateX(0);
    transform: translateX(0);
}

:global .rw-calendar-transition-exiting.rw-calendar-transition-top {
    -ms-transform: translateY(100%);
    transform: translateY(100%);
}

:global .rw-calendar-transition-exiting.rw-calendar-transition-bottom {
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
}

:global .rw-calendar-transition-exiting.rw-calendar-transition-right {
    -ms-transform: translateX(100%);
    transform: translateX(100%);
}

:global .rw-calendar-transition-exiting.rw-calendar-transition-left {
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
}

:global .rw-select-list {
    overflow: auto;
    position: relative;
}

:global .rw-select-list .rw-list {
    max-height: none;
    font-size: 1em;
}

:global .rw-select-list-label {
    display: block;
    position: relative;
    font-weight: normal;
    cursor: inherit;
    padding-left: 20px;
    margin: 0;
}

:global .rw-rtl .rw-select-list-label {
    padding-left: 0;
    padding-right: 20px;
}

:global input.rw-select-list-input {
    position: absolute;
    left: 0;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    top: 0.1em\9;
    margin: 0;
    line-height: normal;
    cursor: inherit;
}

:global .rw-rtl input.rw-select-list-input {
    left: auto;
    right: 0;
}

:global .rw-loading-mask {
    content: '';
    background: url("../img/loader-big.gif") no-repeat center;
    position: absolute;
    background-color: #fff;
    border-radius: 4px;
    opacity: .7;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

:global .rw-multiselect {
    cursor: text;
}

:global .rw-multiselect .rw-input-reset {
    height: 30px;
    /*margin-top:-34px;*/
    /*height:2.429em\9;*/
    border-width: 0;
    width: auto;
    max-width: 100%;
    padding: 0 .857em;
    font-size: 14px;
    font-weight: 600;
    display: inline;
}

:global .rw-multiselect .rw-input-reset[aria-expanded="false"] {
    display: none;
}

:global .rw-multiselect .rw-select {
    vertical-align: middle;
}

:global .rw-multiselect .rw-select,
:global .rw-multiselect .rw-select:hover,
:global .rw-multiselect .rw-select:active {
    box-shadow: none;
    background: none;
}

:global .rw-multiselect-taglist {
    margin: 0;
    padding: 0;
    list-style: none;
    display: inline;
    vertical-align: 0;
    outline: none;
}

:global .rw-multiselect-tag {
    display: inline-table;
    padding: 5px 4px 4px 11px;
    margin: 4px 2px;
    /*height:32px;*/
    border-radius: 3px;
    cursor: default;
    vertical-align: top;
    text-align: center;
    overflow: hidden;
    max-width: 100%;
    font-size: 14px;
    line-height: 1;
    letter-spacing: -0.17px;
    color: #444444;
    font-weight: bold;
    background-color: #FEFCDD;
    border: 1px solid rgba(255,255,255,0.30);
    box-shadow: 0 1px 1px 0 rgba(92,78,78,0.10);
}

:global .rw-multiselect-tag>* {
    display: table-cell;
    vertical-align: middle;
    height: 100%;
}

:global .rw-rtl .rw-multiselect-tag {
    margin-left: 0;
    margin-right: calc(0.279335em - 1px);
    padding: 0 .35em 0 .35em;
}

:global .rw-multiselect-tag.rw-state-focus,
:global .rw-multiselect-tag.rw-state-focus:hover {
    background-color: transparent;
    border-color: #66afe9;
    color: #333;
}

:global .rw-multiselect-tag.rw-state-readonly,
:global .rw-multiselect-tag.rw-state-disabled,
:global .rw-state-readonly .rw-multiselect-tag,
:global .rw-state-disabled .rw-multiselect-tag,
:global fieldset[disabled] .rw-multiselect-tag {
    cursor: not-allowed;
}

:global .rw-multiselect-tag.rw-state-disabled,
:global .rw-state-disabled .rw-multiselect-tag,
:global fieldset[disabled] .rw-multiselect-tag {
    opacity: .65;
}

:global fieldset[disabled] .rw-multiselect-tag {
    box-shadow: none;
    cursor: not-allowed;
}

:global .rw-multiselect-tag-btn {
    margin-left: 5px;
    background-image: url("../img/cross.svg");
    background-repeat: no-repeat;
    display: block;
    height: 12px;
    width: 12px;
    margin-top: -1px;
}

:global .rw-multiselect-tag-btn span {
    display: none;
}

:global .rw-rtl .rw-multiselect-tag-btn {
    margin-left: 0;
    margin-right: .25em;
}

:global .rw-autocomplete .rw-select {
    position: absolute;
    display: block;
    width: auto;
    top: 0;
    bottom: 0;
    right: 0;
}

:global .rw-popup-container {
    position: absolute;
    z-index: 1005;
    top: 100%;
    left: -6px;
    right: -6px;
}

:global .rw-popup-container.rw-dropup {
    top: auto;
    bottom: 100%;
}

:global .rw-state-focus .rw-popup-container {
    z-index: 1006;
}

:global .rw-popup-transition {
    width: 100%;
    margin-bottom: 6px;
    padding: 0 6px;
}

:global .rw-dropup>.rw-popup-transition {
    margin-bottom: 0;
    margin-top: 6px;
}

:global .rw-popup {
    background: #fff;
    border: 1px solid #DCDEE0;
    box-shadow: 0 0 16px -8px rgba(0, 0, 0, 0.16);
    border-radius: 0 0 2px 2px;
}

:global .rw-dropup .rw-popup {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.2);
}

:global .rw-popup-transition {
    transition: transform 200ms;
}

:global .rw-popup-transition-entering {
    overflow: hidden;
}

:global .rw-popup-transition-entering .rw-popup-transition {
    -ms-transform: translateY(0);
    transform: translateY(0);
    transition-timing-function: ease-out;
}

:global .rw-popup-transition-exiting .rw-popup-transition {
    transition-timing-function: ease-in;
}

:global .rw-popup-transition-exiting,
:global .rw-popup-transition-exited {
    overflow: hidden;
}

:global .rw-popup-transition-exiting .rw-popup-transition,
:global .rw-popup-transition-exited .rw-popup-transition {
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
}

:global .rw-popup-transition-exiting.rw-dropup .rw-popup-transition,
:global .rw-popup-transition-exited.rw-dropup .rw-popup-transition {
    -ms-transform: translateY(100%);
    transform: translateY(100%);
}

:global .rw-popup-transition-exited {
    display: none;
}

:global .rw-state-disabled {
    box-shadow: none;
    cursor: not-allowed;
}


/* types-filter */
:global .types-filter {
    width: 100%;
}

:global .types-filter .rw-widget-input,
:global .types-filter.rw-state-focus .rw-widget-input {
    border: 0;
    background-color: transparent;
    box-shadow: none;
    cursor: pointer;
    border-radius: 4px;
}

:global .types-filter.rw-open .rw-widget-input {
    border: 0;
    background-color: #E5E8ED;
    box-shadow: none;
}

:global .types-filter.rw-open .rw-widget-input .rw-i-caret-down {
    transform: rotate(180deg);
}

:global .types-filter .rw-popup-container {
    top: calc(100% + 4px);
}

:global .types-filter .rw-list-option:hover {
    background-color: #F1F2F6;
    border-radius: 4px;
}

:global .types-filter .rw-popup {
    padding: 4px;
    background-color: #FFFFFF;
    border: 1px solid #E5E8ED;
    box-sizing: border-box;
    box-shadow: 0 6px 14px -6px rgba(24, 39, 75, 0.12), 0 10px 32px -4px rgba(24, 39, 75, 0.1);
    border-radius: 4px;
}
