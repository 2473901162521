.container {
  position: relative;
}

.inputContainer {
  display: flex;
  background: #FCFCFD;
  border: 1px solid #C6CCD2;
  border-radius: 8px;
  padding: 6px;
}

.input {
  flex-grow: 1;
  border: none;
  outline: none;
  height: 30px;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #000000;

  &:focus {
    border: none;
    outline: none;
  }
}

.values {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.value {
  background-color: #001AFF;
  border-radius: 4px;
  padding: 4px;
  margin: 2px;
}

.valueLabel {
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  color: #FCFCFD;
  display: inline-block;
  margin-left: 4px;
}

.valueLabelPrev {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #CBE6FE;
}

.closeIcon {
  margin-left: 4px;
  display: inline-block;
  cursor: pointer;
}

.optionLabelPrev {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #909398;
  width: 82px;
  display: inline-block;
}

.optionLabel {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #2D313A;
}

.optionsContainer {
  padding: 4px;
  width: 100%;
  position: absolute;
  left: -5px;
  top: 48px;
  background-color: #fff;
  z-index: 3;
  border: 1px solid #E2E2E4;
  box-shadow: 0 2px 16px -8px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
}

.options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.option {
  margin-top: 4px;
  padding: 12px;
  cursor: pointer;
  border-radius: 8px;

  &:hover {
    background: #0014DB;

    .optionLabelPrev,
    .optionLabel {
      color: #fff;
    }
  }
}

.error {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #db2328;
  text-align: center;
  padding: 8px;
}

.noMatchesAlert {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2D313A;
  text-align: center;
  padding: 8px;
}
