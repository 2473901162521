.container {
    padding: 7px;
    width: 100%;
    margin: 4px 0;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: #2d313a;
    cursor: pointer;
    background-color: transparent;
    border: none;
}
