.container {
    padding: 8px 12px 12px;
    background-color: #DBFCD6;
    border-radius: 8px;
    margin-top: 8px;
}

.body {
    font-size: 13px;
    line-height: 18px;
    color: #2D313A;
    font-weight: 600;
}

.date {
    text-align: right;
}

.footer {
    margin-top: 8px;
    font-size: 12px;
    line-height: 16px;
    color: #495665;
    display: flex;
}

.userName {
    flex-grow: 1;
}
