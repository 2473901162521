.container {
    width: 120px;
    /*height: 132px;*/
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
}

.radioButton {
    height: 0;
    width: 0;
    border: none;
    opacity: 0;
    margin: 0;
}

.label {
    cursor: pointer;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #E5E8ED;
    border-radius: 8px;
}

.radioButton:checked + .label {
    background: #D8DEE4;
    border-color: #D8DEE4;
}

.avatar {
    width: 60px;
    height: 60px;
}

.firstName,
.lastName,
.position {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #1E1F21;
}

.firstName {
    margin-top: 8px;
}

.lastName {
    margin-top: 4px;
}

.position {
    margin-top: 4px;
    font-size: 11px;
    color: #848484;
}

.container {

}
