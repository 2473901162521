.field {
    display: none;
}

.field:checked + label {
    border-radius: 2px;
    box-shadow: 0 0 2px 0 rgba(0,0,0,0.05), 0 1px 4px 0 rgba(0,0,0,0.15);
    background-color: #fff;
}

.label {
    font-size: 12px;
    line-height: 1;
    font-weight: 600;
    padding: 10px 16px;
    cursor: pointer;
    display: block;
    color: #494a4c;
}

.label:hover {
    color: #000;
}

.field:checked + label {
    cursor: default;
}

.field:checked + .status-default {
    color: #000;
}

