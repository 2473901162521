body {
  margin: 0;
}

/* TODO: Перенести эти стили в стили таблицы (в компонет) */
:global .MuiTablePagination-input {
  display: none !important;
}

:global .MuiTableSortLabel-icon {
  font-size: 12px !important;
}

:global .MuiTableSortLabel-active span,
:global .MuiTableSortLabel-active svg {
  color: #1e1f21 !important;
}

:global .MuiTablePagination-toolbar {
  min-height: 100% !important;
}

/*:global .ew {*/
/*  font-family: 'Open Sans';*/
/*}*/
