.container {
    list-style: none;
    padding-left: 10px;
    margin: 0 0 0 -10px;
    display: flex;
    height: 48px;
    width: calc(100vw - 77px);
    position: sticky;
    top: 124px;
    background-color: #f7f8fa;
    z-index: 3;
}

.item {
    padding: 15px 18px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    font-size: 13px;
    font-weight: 600;
    color: #757678;
}

.item:hover {
    color: #000;
}

.item.active {
    border-color: #2d313a;
    color: #000;
}

.item.active2 {
    border-color: #2d313a;
    color: #0002fa;
}


.itemDisable {
    pointer-events: none;
}


.wrapperItemDisable {
    cursor: pointer;
    height: min-content;
}

