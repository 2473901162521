.wrapper {
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    box-shadow: 0 2px 34px -10px rgba(0, 33, 63, 0.1);
    background-image: url("./pattern@3x.jpg");
    background-size: 242px;
}

.container {
    width: 320px;
    background-color: #fff;
    padding: 40px 60px 50px 60px;
    box-shadow: 0 2px 34px -10px rgba(0, 33, 63, 0.1);
}

.mainLogo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column
}

.logo {
    margin-bottom: 24px;
}

.resetPassLink {
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    color: #3c3c3c;
    display: block;
    margin-top: 12px;
    cursor: pointer;
}

.fieldsSection {
    margin-top: 16px;
}

.submitError {
    color: red;
    margin-top: 8px;
}
