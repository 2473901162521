.container {
    padding: 7px;
    width: 100%;
    background: linear-gradient(360deg, #000000 6.25%, #35383D 100%);
    box-shadow: 0 1px 4px -4px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    margin: 4px 0;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: #fff;
    cursor: pointer;
}
