.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    padding: 57px 0 0 60px;
    height: calc(100vh - 60px);
    background: #F1F2F6;
}

.inner {
    display: flex;
    justify-content: space-between;
    height: 100%;
    overflow: hidden;
    flex-grow: 1;
}



.contentWrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
}

.active {
    display: block;
    font-size: 13px;
    color: #666668;
}
