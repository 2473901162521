.groupSection {
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.11);
    background-color: #fff;
    padding: 20px 20px;
}

.groupSection:not(:first-child) {
    margin-top: 8px;
}

.groupSection > *:not(:first-child) {
    margin-top: 16px;
}

.delimiter:not(:first-child) {
    margin: 20px -20px!important;
    height: 1px;
    background-color: #ededed;
}

.checkboxGroup {
    display: flex;
    flex-wrap: wrap;
    margin: -14px -8px -14px -8px;
    position: relative;
}

.checkboxGroup > * {
    margin: 14px 8px 18px 12px;
}
