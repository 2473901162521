.container {
    padding: 8px 12px 12px;
    background: #F7F8FA;
    border-radius: 8px;
    margin-top: 8px;
}

.body {

}

.date {
    text-align: right;
}

.description {
    font-size: 13px;
    line-height: 18px;
    color: #2d313a;
    word-wrap: break-word;
}

.files {
    margin-top: 8px;
}

.images {
    margin-top: 8px;
}


.footer {
    margin-top: 8px;
    font-size: 12px;
    line-height: 16px;
    color: #495665;
    display: flex;
}

.userName {
    flex-grow: 1;
}
