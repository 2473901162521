.container {
    font-size: 13px;
    font-weight: 400;
    padding: 0 20px;
    height: 32px;
    display: flex;
    align-items: center;
    background-color: #FFFFFF;
    cursor: pointer;
    color: #2D313A;
    position: relative;
    z-index: 1;
    text-decoration: none;
    border: none;
    width: 100%;
    text-align: left;
}

.container:hover{
    background-color: #F4F5F6;
}


.container:not(:last-child) {
    border-bottom: solid 1px #EFEFF0;
}

.container:active {
    background-color: #F4F5F6;
}

.longPressWrapper {
    background-color: #000 !important;
}

@keyframes slideIn {
    from {
        width: 0;
        opacity: 0.6;
    }

    to {
        width: 100%;
        opacity: 1;
    }
}

.longPress {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    animation-duration: 1.5s;
    animation-name: slideIn;
    animation-timing-function: ease-out;
    background-color: #b00016;
}

.children {
    z-index: 4;
    position: relative;
}

.childrenProcess {
    z-index: 4;
    display: none;
}

.container:hover .children {
    display: none;
}

.container:hover .childrenProcess {
    display: block;
}
