.title {
    font-size: 15px;
    line-height: 22px;
    color: #657B90;
    padding: 6px 16px;
    background-color: #F7F8FA;
    border-bottom: 1px solid #D8DEE4;
}

.item {
    padding: 16px;
    display: flex;
}

.fieldWrapper {
    flex-shrink: 0;
    width: 280px;
}

.buildingType {
    flex-grow: 1;
    font-size: 13px;
    line-height: 16px;
    color: #2D313A;
}

.categoryField {

}

.disabledCheckbox {
    position: relative;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    color: #000;
    padding-left: 28px;
    padding-top: 4px;
    cursor: no-drop;
}

.disabledCheckbox:before {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    margin-top: -8px;
    border: solid 2px #000;
    border-radius: 2px;
    background-color: gray;
    position: absolute;
    left: 0;
    top: 11px;
}
