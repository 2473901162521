.dzu-dropzone-wrapper {
    position: relative;
    /*display: flex;*/
    /*flex-wrap: wrap;*/
    /*align-items: center;*/
    width: 100%;
    /*min-height: 80px;*/
    box-sizing: border-box;
    transition: all .15s linear;
    border-radius: 2px;
    padding: 16px 16px 32px;
    box-shadow: inset 0 0 16px 0 rgba(0, 0, 0, 0.08);
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='2' ry='2' stroke='%23C9CFD3FF' stroke-width='1' stroke-dasharray='8%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

:global .dzu-dropzone {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    /*display: flex;*/
    /*flex-wrap: wrap;*/
    /*align-items: center;*/
    /*width: 100%;*/
    /*max-height: 490px;*/
    /*min-height: 80px;*/
    /*overflow-y: hidden;*/
    /*margin: 0 auto;*/
    /*position: relative;*/
    /*box-sizing: border-box;*/
    /*transition: all .15s linear;*/
    /*border-radius: 2px;*/
    /*padding: 16px 16px 68px;*/
    /*box-shadow: inset 0 0 16px 0 rgba(0, 0, 0, 0.08);*/
    /*background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='2' ry='2' stroke='%23C9CFD3FF' stroke-width='1' stroke-dasharray='8%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");*/
}

:global .dzu-fileName {
    font-size: 12px;
    font-weight: 600;
    color: #454545;
    margin-top: 4px;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 17px;
    white-space: nowrap;
}

:global .dzu-previewWrapperDone progress {
    display: none !important;
}

:global .dzu-previewWrapperLoading .dzu-previewImage {
    display: none;
}

:global .dzu-dropzoneActive {
    background-color: #DEEBFF;
    border-color: #2484FF;
}

:global .dzu-dropzoneDisabled {
    opacity: 0.5;
}

:global .dzu-dropzoneDisabled *:hover {
    cursor: unset;
}

:global .dzu-input {
    display: none;
}

:global .dzu-inputLabel {
    text-align: center;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 2;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    /*position: absolute;*/
    /*top: 0;*/
    /*bottom: 0;*/
    /*left: 0;*/
    /*right: 0;*/
    font-family: 'Open Sans';
    font-weight: 600;
    cursor: pointer;
    font-size: 13px;
    color: #3C3C3C;
}

:global .dzu-inputLabelWithFiles {
    position: absolute;
    bottom: 20px;
    left: 25px;
    width: 440px;
    border-radius: 2px;
    box-shadow: 2px 2px 2px -2px rgba(0, 0, 0, 0.15), -2px -2px 4px 0 #ffffff;
    border: solid 1px rgba(255, 255, 255, 0.1);
    background-color: #f7f8fa;
    color: #3c3c3c;

    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    min-height: 32px;
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 600;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    cursor: pointer;
}

:global .dzu-previewWrapper {
    margin: 4px;
    width: 104px;
    overflow: hidden;
}

:global .dzu-previewContainer {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 104px;
}

:global .dzu-fileErrorMessageContainer {
    height: 104px;
    position: relative;
}

:global .dzu-fileErrorMessageContainer .dzu-previewContainer {
    position: unset;
    height: 0;
}

:global .dzu-fileErrorTitle {
    font-size: 11px;
    font-weight: 600;
    color: #454545;
}

:global .dzu-fileErrorMessage {
    font-size: 10px;
    font-weight: 600;
    color: #454545;
    margin-top: 8px;
}

:global .dzu-previewFileNameError {
    display: none;
}

:global .dzu-previewFileNameError + span {
    display: none;
}

:global .dzu-previewStatusContainer {
    display: flex;
    align-items: center;
}

:global .dzu-previewStatusContainer progress {
    display: block;
    color: #007aff;
    background: #007aff;
    border: 2px solid #f9fafb;
    border-radius: 5px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    height: 10px;
}

::-webkit-progress-bar {
    background: #f9fafb;
}
::-webkit-progress-value {
    background: #007aff;
}
::-moz-progress-bar {
    background: #f9fafb;
}

:global .dzu-previewFileName {
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    width: 104px;
    height: 104px;
}

:global .dzu-previewImage {
    height: 104px;
    min-width: 104px;
    align-self: center;
    border-radius: 2px;
    object-fit: cover;
}

:global .dzu-previewButton {
    position: absolute;
    right: 4px;
    top: 4px;
    opacity: 0;
    background-size: 14px 14px;
    background-position: center;
    background-repeat: no-repeat;
    width: 14px;
    height: 14px;
    cursor: pointer;
    background-image: url('../../theme/icons/cross_light.svg') !important;
    z-index: 4;
}

:global .dzu-previewWrapper:hover .dzu-previewButton {
    opacity: 1;
}

:global .dzu-submitButtonContainer {
    margin: 24px 0;
    z-index: 1;
}

:global .dzu-submitButton {
    padding: 0 14px;
    min-height: 32px;
    background-color: #2484FF;
    border: none;
    border-radius: 4px;
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 600;
    color: #FFF;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    cursor: pointer;
}

:global .dzu-submitButton:disabled {
    background-color: #E6E6E6;
    color: #333333;
    cursor: unset;
}

:global .dzu-fileIconWrapper {
    height: 104px;
    width: 104px;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

:global .dzu-fileIconContainer {
    position: relative;
}

:global .dzu-fileIcon {
    width: 38px;
    height: 48px;
}

:global .dzu-fileIconContainer .dzu-previewContainer {
    position: relative;
    z-index: 3;
}

:global .dzu-fileIconContainer .dzu-previewFileName {
    color: transparent;
}
