:global .react-month-picker {

}

:global .react-month-picker .month-input {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 2;
}

:global .react-month-picker .calendar-container {
    width: 176px;
    border-radius: 2px;
    box-shadow: 0 0 16px -8px #2d313a;
    background-color: #2d313a;
    position: absolute;
    top: 4px;
    left: 0;
    z-index: 2;
    padding: 8px 12px;
}

/*  SECTIONS  */
:global .react-month-picker .section_mp {
    clear: both;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
}

/*  COLUMN SETUP  */
:global .react-month-picker .col_mp {
    display: block;
    float: left;
    text-align: center;
}
:global .react-month-picker .col_mp:first-child {
    margin-left: 0;
}

/*  GROUPING  */
:global .react-month-picker .group_mp:before,
:global .react-month-picker .group:after {
    content: "";
    display: table;
}
:global .react-month-picker .group_mp:after {
    clear: both;
}
:global .react-month-picker .group_mp {
    zoom: 1; /* For IE 6/7 */
}

/*  GRID OF THREE  */
:global .react-month-picker .span_1_of_3_mp {
    width: 33.33%;
}
:global .react-month-picker .col_mp {
    padding-bottom: 7px;
    padding-top: 7px;
    font-family: 'Open Sans';
    font-size: 13px;
    font-weight: 600;
    color: #cccdcf;
}
:global .react-month-picker .col_mp:hover {
    background-color: #1e1f21;
    color: #fff;
    cursor: pointer;
}
:global .react-month-picker .calendar-container.readonly .col_mp:hover {
    background-color: transparent;
    cursor: default;
    color: inherit;
}
:global .react-month-picker .selected_date_mp {
    font-family: 'Open Sans';
    font-size: 15px;
    font-weight: bold;
    color: #fff;
    padding-left: 8px;
    padding-right: 8px;
}
:global .react-month-picker .selected_cell {
    color: #000;
    background-color: #fff;
    border-radius: 2px;
}
:global .react-month-picker .arrows_mp {
    font-weight: bold;
    font-size: 18px;
    font-family: monospace;
    color: #fff;
}
:global .react-month-picker .month-input.readonly {
    background-color: #eee;
}

/*@media only screen and (max-width: 360px) {*/
/*    .react-month-picker .col_mp {*/
/*        font-size: 14px;*/
/*    }*/
/*}*/
